import React from 'react'
import { navigate } from 'gatsby'
import PageHelmet from '../components/pageHelmet'

// @ts-ignore
import NotFound from 'icons/not-found.svg'

// Styles
import 'styles/global.scss'
import 'styles/404.scss'

// markup
const NotFoundPage = () => {
  return (
    <PageHelmet title="Page not Found">
      <div className="not-found-container">
        <div className="not-found-wrapper">
          <img className="not-found-image" src={NotFound} alt="not-found" />
          <p className="not-found-header">Error 404</p>
          <p className="not-found-reason">Page not Found</p>
          <p className="not-found-redirect" onClick={() => navigate('/')}>
            Go Home
          </p>
        </div>
      </div>
    </PageHelmet>
  )
}

export default NotFoundPage
